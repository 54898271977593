import { useState, useEffect } from "react";

import { Logger } from "aws-amplify";
import { useCart } from "../../context/cart-context";
import { useNavigate } from "react-router-dom";
import { convertCentsToDollars } from "../../util/util";
import { useMutation } from "@apollo/client";
import { CREATE_ORDER, CREATE_STRIPE_PAYMENT_INTENT } from "../../graphql/customMutations";
import { EPromotionType, IGET_RESTAURANT_CATEGORY, IGET_RESTAURANT_PRODUCT } from "../../graphql/customQueries";
import { orderPlacedPath, restaurantListPath } from "../main";
import { ProductModal } from "../modals/product";
import { ICartProduct, IPreSelectedModifiers, ICartModifierGroup, EPaymentMethod } from "../../model/model";
import { useUser } from "../../context/user-context";
import { PageWrapper } from "../../tabin/components/pageWrapper";
import { Button } from "../../tabin/components/button";
import { useRegister } from "../../context/register-context";
import { toLocalISOString } from "../../util/util";
import { useRestaurant } from "../../context/restaurant-context";
import { TextArea } from "../../tabin/components/textArea";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Link } from "../../tabin/components/link";
import { Auth } from "./auth/auth";
import { CloseIcon } from "../../tabin/components/icons/closeIcon";
import { toast } from "../../tabin/components/toast";
import { FullScreenSpinner } from "../../tabin/components/fullScreenSpinner";
import { isMobile } from "react-device-detect";
import { OrderScheduleDateTime } from "../../tabin/components/orderScheduleDateTime";
import { useErrorLogging } from "../../context/errorLogging-context";
import { PromotionCodeModal } from "../modals/promotionCodeModal";
import { OrderThresholdMessageModal } from "../modals/orderThresholdMessageModal";

import "./checkout.scss";
import { ProductModifier } from "../shared/productModifier";
import { Radio } from "../../tabin/components/radio";
import { Stepper } from "../../tabin/components/stepper";

const logger = new Logger("checkout");

// Component
export const Checkout = () => {
    const navigate = useNavigate();

    // context
    const {
        orderType,
        products,
        notes,
        setNotes,
        tableNumber,
        clearCart,
        promotion,
        total,
        surcharge,
        subTotal,
        updateProduct,
        updateProductQuantity,
        deleteProduct,
        addProduct,
        userAppliedPromotionCode,
        removeUserAppliedPromotion,
        setIsShownUpSellCrossSellModal,
        isShownOrderThresholdMessageModal,
        setIsShownOrderThresholdMessageModal,
        orderScheduledAt,
        updateOrderScheduledAt,
        guestInformation,
        paymentMethod,
        setPaymentMethod,
        covers,
        setCovers,
    } = useCart();
    const { restaurant } = useRestaurant();
    const { user } = useUser();
    const { logError } = useErrorLogging();

    const [showSpinner, setShowSpinner] = useState(false);

    const stripe = useStripe();
    const elements = useElements();

    const [createStripePaymentIntentMutation] = useMutation(CREATE_STRIPE_PAYMENT_INTENT, {
        update: (proxy, mutationResult) => {},
    });

    const [createOrderMutation] = useMutation(CREATE_ORDER, {
        update: (proxy, mutationResult) => {
            logger.debug("mutation result: ", mutationResult);
        },
    });

    // state
    const [productToEdit, setProductToEdit] = useState<{
        product: ICartProduct;
        displayOrder: number;
    } | null>(null);
    const [showEditProductModal, setShowEditProductModal] = useState(false);
    const [showPromotionCodeModal, setShowPromotionCodeModal] = useState(false);
    const [showOrderThresholdMessageModal, setShowOrderThresholdMessageModal] = useState(false);

    // const [showItemUpdatedModal, setShowItemUpdatedModal] = useState(false);

    const { register } = useRegister();

    useEffect(() => {
        if (showEditProductModal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }
    }, [showEditProductModal]);

    if (!restaurant) {
        navigate(restaurantListPath);
        return <div>Invalid restaurant</div>;
        // throw "Restaurant is invalid";
    }

    if (!register) {
        throw "Register is not valid";
    }

    // callbacks
    const onClosePromotionCodeModal = () => {
        setShowPromotionCodeModal(false);
    };

    const onCloseEditProductModal = () => {
        setProductToEdit(null);
        setShowEditProductModal(false);
    };

    const onCloseOrderThresholdMessageModal = () => {
        setShowOrderThresholdMessageModal(false);
    };

    // const onCloseItemUpdatedModal = () => {
    //     setShowItemUpdatedModal(false);
    // };

    const onEditProduct = (product: ICartProduct, displayOrder: number) => {
        setProductToEdit({ product, displayOrder });
        setShowEditProductModal(true);
    };

    const onUpdateProductQuantity = (displayOrder: number, productQuantity: number) => {
        updateProductQuantity(displayOrder, productQuantity);
    };

    const onRemoveProduct = (displayOrder: number) => {
        deleteProduct(displayOrder);
    };

    const onNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(e.target.value);
    };

    // submit callback
    const createOrder = async (stripePaymentId?: string) => {
        const now = new Date();

        // if (!user) {
        //     await logError(
        //         JSON.stringify({
        //             error: "Invalid user",
        //             context: { orderRestaurantId: restaurant.id },
        //         })
        //     );
        //     throw "Invalid user";
        // }
        if (!orderType) {
            await logError(
                "Invalid order type",
                JSON.stringify({
                    error: "Invalid order type",
                    context: { orderRestaurantId: restaurant.id, orderType: orderType },
                })
            );
            throw "Invalid order type";
        }
        if (!restaurant) {
            await logError("Invalid restaurant", JSON.stringify({ restaurant: restaurant }));
            throw "Invalid restaurant";
        }
        if (!products || products.length == 0) {
            await logError(
                "No products have been selected",
                JSON.stringify({
                    error: "No products have been selected",
                    context: { orderRestaurantId: restaurant.id },
                })
            );
            throw "No products have been selected";
        }
        let variables;
        try {
            variables = {
                status: "NEW",
                paid: true,
                // cashPayment: false,
                onlineOrder: true,
                guestCheckout: true, //TODO: Update later when actual auth is added
                stripePaymentId: stripePaymentId,
                orderScheduledAt: orderScheduledAt,
                orderScheduledAtUtc: orderScheduledAt ? new Date(orderScheduledAt).toISOString() : null,
                customerInformation:
                    tableNumber === null
                        ? {
                              // firstName: user.firstName, //TODO: Change to actual user information when auth is added back.
                              // email: user.email,
                              // phoneNumber: user.phoneNumber,

                              firstName: guestInformation.firstName,
                              email: guestInformation.email,
                              phoneNumber: guestInformation.phoneNumber,
                          }
                        : undefined,
                type: orderType,
                number: "Web", //TODO: Remove later
                covers: register && register.enableCovers ? covers : undefined,
                table: tableNumber,
                paymentAmounts: { cash: 0, eftpos: 0, online: stripePaymentId ? subTotal : 0 },
                payments: stripePaymentId
                    ? {
                          type: "ONLINE",
                          amount: subTotal,
                      }
                    : undefined,
                notes: notes,
                total: total,
                surcharge: surcharge || undefined,
                discount: promotion ? promotion.discountedAmount : undefined,
                promotionId: promotion ? promotion.promotion.id : undefined,
                promotionType: promotion ? promotion.promotion.type : undefined,
                subTotal: subTotal,
                registerId: register.id,
                products: JSON.parse(JSON.stringify(products)) as ICartProduct[], // copy obj so we can mutate it later
                placedAt: toLocalISOString(now),
                placedAtUtc: now.toISOString(),
                orderUserId: restaurant.restaurantManagerId, //TODO: Update later when actual auth is added
                orderRestaurantId: restaurant.id,
            };
            if (restaurant.autoCompleteOrders) {
                variables.status = "COMPLETED";
                //@ts-ignore
                variables.completedAt = toLocalISOString(now);
                //@ts-ignore
                variables.completedAtUtc = now.toISOString();
                variables.paid = true;
            }
        } catch (e) {
            await logError(
                "Error in createOrderMutation input",
                JSON.stringify({
                    error: "No products have been selected",
                    context: {
                        status: "NEW",
                        paid: true,
                        // cashPayment: false,
                        onlineOrder: true,
                        guestCheckout: true, //TODO: Update later when actual auth is added
                        stripePaymentId: stripePaymentId,
                        orderScheduledAt: orderScheduledAt,
                        orderScheduledAtUtc: orderScheduledAt ? new Date(orderScheduledAt).toISOString() : null,
                        customerInformation:
                            tableNumber === null
                                ? {
                                      // firstName: user.firstName, //TODO: Change to actual user information when auth is added back.
                                      // email: user.email,
                                      // phoneNumber: user.phoneNumber,

                                      firstName: guestInformation.firstName,
                                      email: guestInformation.email,
                                      phoneNumber: guestInformation.phoneNumber,
                                  }
                                : undefined,
                        type: orderType,
                        number: "Web", //TODO: Remove later
                        covers: register && register.enableCovers ? covers : undefined,
                        table: tableNumber,
                        notes: notes,
                        paymentAmounts: { cash: 0, eftpos: 0, online: subTotal },
                        payments: {
                            type: "ONLINE",
                            amount: subTotal,
                        },
                        total: total,
                        discount: promotion ? promotion.discountedAmount : undefined,
                        promotionId: promotion ? promotion.promotion.id : undefined,
                        promotionType: promotion ? promotion.promotion.type : undefined,
                        subTotal: subTotal,
                        surcharge: surcharge || undefined,
                        registerId: register.id,
                        products: JSON.parse(JSON.stringify(products)) as ICartProduct[], // copy obj so we can mutate it later
                        placedAt: toLocalISOString(now),
                        placedAtUtc: now.toISOString(),
                        orderUserId: restaurant.restaurantManagerId, //TODO: Update later when actual auth is added
                        orderRestaurantId: restaurant.id,
                    },
                })
            );
            throw "Error in createOrderMutation input";
        }
        try {
            if (notes == null || notes == "") {
                //@ts-ignore
                delete variables.notes;
            }
            variables.products.forEach((product) => {
                if (product.modifierGroups.length == 0) {
                    //@ts-ignore
                    delete product.modifierGroups;
                }
                if (product.image == null) {
                    //@ts-ignore
                    delete product.image;
                }
                if (product.notes == null || product.notes == "") {
                    //@ts-ignore
                    delete product.notes;
                }
                if (product.category.image == null) {
                    //@ts-ignore
                    delete product.category.image;
                }
            });
            // process order
            const res = await createOrderMutation({
                variables: variables,
            });
            logger.debug("process order mutation result: ", res);
        } catch (e) {
            console.log("process order mutation error: ", e);

            await logError(e, JSON.stringify({ error: e, variables: variables }));
            throw e;
        }
    };

    const onCreateTransaction = async () => {
        // if (!user) {
        //     toast.error("You must log in before placing an order.");
        //     return;
        // }

        if (restaurant.orderThresholds?.enable && restaurant.orderThresholdMessage && !isShownOrderThresholdMessageModal) {
            setShowOrderThresholdMessageModal(true);
            return;
        }

        if (!paymentMethod) {
            toast.error("Please select a payment type");
            return;
        }

        if (orderScheduledAt == "INVALID" || !orderScheduledAt) {
            toast.error("Please select a valid order time before placing an order.");
            return;
        }

        if (
            tableNumber === null &&
            (!guestInformation.email ||
                guestInformation.emailError ||
                !guestInformation.firstName ||
                guestInformation.firstNameError ||
                !guestInformation.phoneNumber ||
                guestInformation.phoneNumberError)
        ) {
            toast.error("Please fill out all customer information before placing an order.");
            return;
        }

        if (!stripe || !elements) {
            toast.error("Failed to configure Stripe correctly. Please try again later.");
            return;
        }

        if (!restaurant.stripeAccountId) {
            alert("This restaurant has not yet been setup with online ordering. Please contact a Tabin representative!");
            return;
        }

        try {
            setShowSpinner(true);

            if (paymentMethod === EPaymentMethod.EFTPOS) {
                const res: any = await createStripePaymentIntentMutation({
                    variables: {
                        amount: subTotal,
                        restaurantId: restaurant.id,
                    },
                });

                const clientSecret = res.data.createStripePaymentIntent.clientSecret;

                const element = elements.getElement(CardElement);

                if (!element) return;

                const cardPayment = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: element,
                    },
                });

                if (cardPayment.error) {
                    throw cardPayment.error.message;
                }

                const stripePaymentId = cardPayment.paymentIntent.id;

                console.log(`PaymentIntent (${cardPayment.paymentIntent?.id}): ${cardPayment.paymentIntent?.status}`);

                await createOrder(stripePaymentId);
            } else {
                await createOrder();
            }

            setShowSpinner(false);

            clearCart();

            navigate(`${orderPlacedPath}`, { state: { tableNumber, orderScheduledAt } });
        } catch (err) {
            alert(err);
            setShowSpinner(false);
        }
    };

    const orderSummary = (
        <OrderSummary
            onNotesChange={onNotesChange}
            onEditProduct={onEditProduct}
            onUpdateProductQuantity={onUpdateProductQuantity}
            onRemoveProduct={onRemoveProduct}
        />
    );

    const onUpdateProduct = (index: number, product: ICartProduct) => {
        updateProduct(index, product);
        // setShowItemUpdatedModal(true);
    };

    const editProductModal = () => {
        let category: IGET_RESTAURANT_CATEGORY | null = null;
        let product: IGET_RESTAURANT_PRODUCT | null = null;

        if (!productToEdit) {
            return <></>;
        }

        restaurant.categories.items.forEach((c) => {
            if (productToEdit.product.category && c.id == productToEdit.product.category.id) {
                category = c;
            }

            c.products &&
                c.products.items.forEach((p) => {
                    if (p.product.id == productToEdit.product.id) {
                        product = p.product;
                    }
                });
        });

        if (!product || !category) {
            return <></>;
        }

        let orderedModifiers: IPreSelectedModifiers = {};

        productToEdit.product.modifierGroups.forEach((mg) => {
            orderedModifiers[mg.id] = mg.modifiers;
        });

        console.log("orderedModifiers", orderedModifiers);

        return (
            <ProductModal
                category={category}
                product={product}
                isOpen={showEditProductModal}
                onClose={onCloseEditProductModal}
                onUpdateProduct={onUpdateProduct}
                editProduct={{
                    orderedModifiers: orderedModifiers,
                    quantity: productToEdit.product.quantity,
                    notes: productToEdit.product.notes,
                    productCartIndex: productToEdit.displayOrder,
                }}
            />
        );
    };

    const promotionCodeModal = () => {
        return <>{showPromotionCodeModal && <PromotionCodeModal isOpen={showPromotionCodeModal} onClose={onClosePromotionCodeModal} />}</>;
    };

    const thresholdMessageModal = () => {
        return (
            <>
                {showOrderThresholdMessageModal && (
                    <OrderThresholdMessageModal
                        isOpen={showOrderThresholdMessageModal}
                        onClose={onCloseOrderThresholdMessageModal}
                        onContinue={() => setIsShownOrderThresholdMessageModal(true)}
                    />
                )}
            </>
        );
    };

    // const itemUpdatedModal = (
    //     <>
    //         {showItemUpdatedModal && <ItemAddedUpdatedModal isOpen={showItemUpdatedModal} onClose={onCloseItemUpdatedModal} isProductUpdate={true} />}
    //     </>
    // );

    const modalsAndSpinners = (
        <>
            {editProductModal()}
            {promotionCodeModal()}
            {thresholdMessageModal()}
            {/* {itemUpdatedModal} */}
        </>
    );

    const cartEmptyDisplay = (
        <>
            <div className="cart-empty">
                <div>No items selected!</div>
                <div className="separator-2"></div>
            </div>
        </>
    );

    const title = (
        <div className="your-order-title mb-3">
            {/* <CachedImage className="image mr-2" url={`${getPublicCloudFrontDomainName()}/images/shopping-bag-icon.png`} alt="shopping-bag-icon" /> */}
            <div className="h2">Your Order</div>

            {isMobile && (
                <span
                    onClick={() => {
                        navigate(-1);
                    }}
                    className="pt-1 pb-1 pl-1 pr-1"
                >
                    <CloseIcon height={"14px"} className="checkout-close-button" />
                </span>
            )}
        </div>
    );

    const onUpdateCoversNumber = (count: number) => {
        setCovers(count);
    };

    const CoversInput = () => (
        <>
            <div className="mt-3 mb-6">
                <div className="h3 text-bold">Number Of Diners</div>
                <div className="separator-2"></div>
                <div>
                    <Stepper count={covers} min={1} max={20} onUpdate={onUpdateCoversNumber} size={32} />
                </div>
            </div>
        </>
    );

    const orderScheduleTitle = (
        <div className="title mb-1 mt-6">
            <div className="h3">Order Time</div>
            <div className="separator-2"></div>
        </div>
    );

    const onChangeScheduleDateTime = (dateTimeLocalISO: string | null) => {
        updateOrderScheduledAt(dateTimeLocalISO);
    };

    const orderTitle = (
        <div className="title mb-1 mt-3">
            <div className="h3">Items</div>
            <div className="separator-2"></div>
        </div>
    );

    const restaurantNotes = (
        <>
            <div className="h3 mb-1 mt-6">Special Instructions</div>
            <div className="separator-2"></div>
            <TextArea placeholder={"Leave a note for the restaurant"} value={notes} onChange={onNotesChange} />
        </>
    );

    const order = (
        <>
            {orderSummary}
            {/* {restaurantNotes} */}
        </>
    );

    const paymentTitle = (
        <div className="title mb-1 mt-6">
            <div className="h3">Payment</div>
            <div className="separator-2"></div>
        </div>
    );

    const orderButton = (
        <div>
            <Button className="complete-order-button mt-2" onClick={onCreateTransaction} disabled={products ? products.length == 0 : true}>
                Order
            </Button>
        </div>
    );

    const onClickApplyPromotionCode = async () => {
        setShowPromotionCodeModal(true);
    };

    const onRemoveUserAppliedPromotionCode = () => {
        removeUserAppliedPromotion();
    };

    const promotionInformation = (
        <>
            {promotion && (
                <div className="checkout-promotion-information mb-2 mt-2 mb-2 pt-2 pr-2 pb-2 pl-2">
                    <div>
                        <div className="checkout-promotion-information-heading h5 mb-1">
                            <div>Promotion Applied!</div>
                            <div>-${convertCentsToDollars(promotion.discountedAmount)}</div>
                        </div>
                        {promotion.promotion.type !== EPromotionType.ENTIREORDER ? (
                            <div className="checkout-promotion-information-items">
                                {promotion.promotion.name}:{" "}
                                {Object.values(promotion.matchingProducts).map((p, index) => (
                                    <>
                                        {index !== 0 && ", "}
                                        {p.name}
                                    </>
                                ))}
                            </div>
                        ) : (
                            <div className="checkout-promotion-information-items">Entire Order</div>
                        )}
                    </div>

                    {userAppliedPromotionCode && (
                        <div className="checkout-remove-promotion" onClick={onRemoveUserAppliedPromotionCode}>
                            <CloseIcon height="12px" />
                        </div>
                    )}
                </div>
            )}
        </>
    );

    return (
        <>
            <PageWrapper>
                {showSpinner && <FullScreenSpinner show={showSpinner} text="Processing your order..." />}
                <div className="checkout">
                    <div className="order-wrapper">
                        {title}

                        {tableNumber === null && (
                            <div className="mb-6">
                                <Auth />
                            </div>
                        )}

                        {register?.enableCovers && <CoversInput />}

                        {tableNumber === null && (
                            <div>
                                {orderScheduleTitle}
                                <OrderScheduleDateTime
                                    onChange={onChangeScheduleDateTime}
                                    operatingHours={restaurant.operatingHours}
                                    preparationTimeInMinutes={restaurant.preparationTimeInMinutes || 10}
                                />
                            </div>
                        )}

                        {orderTitle}
                        {promotionInformation}
                        <div className="order">{products && products.length > 0 ? order : cartEmptyDisplay}</div>
                        {/* 
                        {promotion && (
                            <div className="mb-2">
                                {`Discount: -$${convertCentsToDollars(promotion.discountedAmount)}`}{" "}
                                {userAppliedPromotionCode && <Link onClick={onRemoveUserAppliedPromotionCode}>Remove</Link>}
                            </div>
                        )} */}

                        {promotion && (
                            <div className="checkout-discount-wrapper mb-2 mt-1">
                                <div>{`Discount: (${promotion.promotion.code})`}</div>
                                <div>-${convertCentsToDollars(promotion.discountedAmount)}</div>
                            </div>
                        )}

                        {surcharge ? (
                            <div className="checkout-discount-wrapper mb-2 mt-1">
                                <div>Surcharge:</div>
                                <div>${convertCentsToDollars(surcharge)}</div>
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="checkout-total-wrapper h4">
                            <div>Total:</div>
                            <div>${convertCentsToDollars(subTotal)}</div>
                        </div>

                        {restaurantNotes}

                        {paymentTitle}
                        <div className="mb-3">
                            <Radio
                                className="pay-by-card mb-2"
                                selected={paymentMethod === EPaymentMethod.EFTPOS}
                                onSelect={() => {
                                    setPaymentMethod(EPaymentMethod.EFTPOS);
                                }}
                            >
                                Pay By Card
                            </Radio>

                            {register.enablePayLater && (
                                <Radio
                                    className="pay-at-counter"
                                    selected={paymentMethod === EPaymentMethod.LATER}
                                    onSelect={() => {
                                        setPaymentMethod(EPaymentMethod.LATER);
                                    }}
                                >
                                    Pay Later At Counter
                                </Radio>
                            )}
                        </div>

                        {paymentMethod === EPaymentMethod.EFTPOS && (
                            <CardElement
                                className="mb-4 stripe-card-element"
                                options={{
                                    hidePostalCode: true,
                                    style: {
                                        base: {
                                            fontSize: "16px",
                                            color: "black",
                                            "::placeholder": {
                                                color: "#aaaaaa",
                                            },
                                        },
                                        invalid: {
                                            color: "#9e2146",
                                        },
                                    },
                                }}
                            />
                        )}

                        <div className="pay-later-link text-center mt-4 mb-3">
                            <Link onClick={onClickApplyPromotionCode}>Apply promo code</Link>
                        </div>
                        <div>{orderButton}</div>
                    </div>
                </div>
                {modalsAndSpinners}
            </PageWrapper>
        </>
    );
};

const OrderSummary = (props: {
    onEditProduct: (product: ICartProduct, displayOrder: number) => void;
    onRemoveProduct: (displayOrder: number) => void;
    onUpdateProductQuantity: (displayOrder: number, productQuantity: number) => void;
    onNotesChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => {
    // context
    const { products } = useCart();

    // displays
    if (!products || products.length === 0) return <h1>No items in cart!</h1>;

    const orderItems = (
        <>
            {products &&
                products.map((product, index) => {
                    // using index as key because products can be duplicated
                    if (product) {
                        return (
                            <div key={index}>
                                <OrderItem
                                    product={product}
                                    displayOrder={index}
                                    onEditProduct={props.onEditProduct}
                                    onUpdateProductQuantity={props.onUpdateProductQuantity}
                                    onRemoveProduct={props.onRemoveProduct}
                                />
                                <div className="separator-2"></div>
                            </div>
                        );
                    }
                })}
        </>
    );

    return <>{orderItems}</>;
};

const OrderItem = (props: {
    product: ICartProduct;
    displayOrder: number;
    onEditProduct: (product: ICartProduct, displayOrder: number) => void;
    onUpdateProductQuantity: (displayOrder: number, productQuantity: number) => void;
    onRemoveProduct: (displayOrder: number) => void;
}) => {
    // constants
    let itemPrice = props.product.price * props.product.quantity;

    props.product.modifierGroups.forEach((mg) => {
        mg.modifiers.forEach((m) => {
            const changedQuantity = m.quantity - m.preSelectedQuantity;

            if (changedQuantity > 0) {
                itemPrice += m.price * changedQuantity * props.product.quantity;
            }
        });
    });

    // displays
    // const quantity = (
    //     <Stepper
    //         count={props.product.quantity}
    //         min={1}
    //         onUpdate={(count: number) => props.onUpdateProductQuantity(props.displayOrder, count)}
    //         size={32}
    //     />
    // );

    return (
        <>
            <div className="order-item">
                {/* {quantity}  */}
                <OrderItemDetails
                    quantity={props.product.quantity}
                    name={props.product.name}
                    price={props.product.price}
                    notes={props.product.notes}
                    modifierGroups={props.product.modifierGroups}
                    // onEditProduct={() => props.onEditProduct(props.product, props.displayOrder)}
                />
                {/* <div className="text-center">
                    <div className="h3 text-primary mb-2">${convertCentsToDollars(itemPrice)}</div>
                    <Button className="remove-button" onClick={() => props.onRemoveProduct(props.displayOrder)}>
                        Remove
                    </Button>
                </div> */}
                {!props.product.isPreSelectedProduct && (
                    <>
                        <Link className="edit-link mt-2 mr-2" onClick={() => props.onEditProduct(props.product, props.displayOrder)}>
                            Edit
                        </Link>
                        <Link className="remove-link mt-2" onClick={() => props.onRemoveProduct(props.displayOrder)}>
                            Remove
                        </Link>
                    </>
                )}
            </div>
        </>
    );
};

const OrderItemDetails = (props: {
    name: string;
    quantity: number;
    price: number;
    notes: string | null;
    modifierGroups: ICartModifierGroup[];
    // onEditProduct: () => void;
}) => {
    const { name, quantity, price, notes, modifierGroups } = props;
    const { isPOS } = useRegister();

    const modifierString = (preSelectedQuantity: number, quantity: number, name: string, price: number) => {
        const changedQuantity = quantity - preSelectedQuantity;
        let mStr = "";

        if (changedQuantity < 0 && Math.abs(changedQuantity) == preSelectedQuantity) {
            mStr = `(REMOVE) ${changedQuantity > 1 ? `${Math.abs(changedQuantity)} x ` : ""}${name}`;
        } else {
            mStr = `${quantity > 1 ? `${Math.abs(quantity)} x ` : ""}${name}`;
        }

        if (price > 0 && changedQuantity > 0) {
            mStr += ` (+$${convertCentsToDollars(price)})`;
        }

        return mStr;
    };

    const nameDisplayString = `${quantity > 1 ? `${quantity} x ` : ""}${name} ($${convertCentsToDollars(price)})`;

    const nameDisplay = (
        <div className="name-edit-button">
            <div className="h4">{nameDisplayString}</div>
        </div>
    );

    const modifiersDisplay = (
        <>
            {modifierGroups.map((mg, index) => (
                <>
                    {!mg.hideForCustomer && (
                        <>
                            <div className="h6 text-bold mt-1" key={mg.id}>
                                {mg.name}
                            </div>
                            {mg.modifiers.map((m) => (
                                <>
                                    <div key={m.id} className="h6 modifier-name">
                                        {modifierString(m.preSelectedQuantity, m.quantity, m.name, m.price)}
                                    </div>
                                    {m.productModifiers && (
                                        <div className="mb-2">
                                            {m.productModifiers.map((productModifier, index) => (
                                                <div>
                                                    <div className="mt-2"></div>
                                                    <ProductModifier
                                                        selectionIndex={m.productModifiers && m.productModifiers.length > 1 ? index + 1 : undefined}
                                                        product={productModifier}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            ))}
                        </>
                    )}
                </>
            ))}
        </>
    );

    const notesDisplay = <>{notes && <div className="text-grey mt-2">Notes: {notes}</div>}</>;

    return (
        <div className="detail">
            {nameDisplay}
            {modifiersDisplay}
            {notesDisplay}
        </div>
    );
};
